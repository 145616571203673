// extracted by mini-css-extract-plugin
export var insuranceBenefitsSection = "F_gb";
export var insuranceBusinessesSection = "F_f8";
export var insuranceCaseStudySection = "F_gd";
export var insuranceClientQuotesSection = "F_gn";
export var insuranceDevExpertiseSection = "F_gk";
export var insuranceDevServicesIconSection = "F_f7";
export var insuranceOurAchievementsSection = "F_gm";
export var insuranceProcessSection = "F_f9";
export var insuranceProjLogoSection = "F_gj";
export var insuranceRequirementsSection = "F_gf";
export var insuranceSkilledSection = "F_gl";
export var insuranceSuccessStoriesSection = "F_gg";
export var insuranceTechStackSection = "F_gc";
export var insuranceWhyHireSection = "F_gh";